import lodash from 'lodash';
import FJNetworkStore from './FJNetworkStore';
import FJEvent from '../lib/FJEvent';
import FJEventConstant from '../constant/FJEventConstant';
import langCn from '../../public/language/local.cn.json';
import langDe from '../../public/language/local.de.json';
import langEn from '../../public/language/local.en.json';
import langEs from '../../public/language/local.es.json';
import langFr from '../../public/language/local.fr.json';
import langJp from '../../public/language/local.jp.json';
import langPt from '../../public/language/local.pt.json';
import langTw from '../../public/language/local.tw.json';
import langIT from '../../public/language/local.it.json';
import langId from '../../public/language/local.id.json';
import langAr from '../../public/language/local.ar.json';

export default (function () {
    let _currentTag = 'en';
    let _languages = {
        cn: langCn,
        de: langDe,
        en: langEn,
        es: langEs,
        fr: langFr,
        jp: langJp,
        pt: langPt,
        tw: langTw,
        it: langIT,
        id: langId,
        ar: langAr,
    };
    return {
        /**
         * @description 通过href识别出的tag
         * @return {string}
         */
        get localTag() {
            const url = document.location.href;
            let tag = 'en';
            if (window.locale) {
                let code = window.locale.toLowerCase();
                if (code.indexOf('cn') !== -1) tag = 'cn';
                else if (code.indexOf('tw') !== -1) tag = 'tw';
                else if (code.indexOf('ja') !== -1) tag = 'jp';
                else if (code.indexOf('fr') !== -1) tag = 'fr';
                else if (code.indexOf('de') !== -1) tag = 'de';
                else if (code.indexOf('es') !== -1) tag = 'es';
                else if (code.indexOf('pt') !== -1) tag = 'pt';
                else if (code.indexOf('it') !== -1) tag = 'it';
                else if (code.indexOf('id') !== -1) tag = 'id';
                else if (code.indexOf('ar') !== -1) tag = 'ar';
            } else {
                if (url.indexOf('/cn/') !== -1) {
                    tag = 'cn';
                } else if (url.indexOf('/tw/') !== -1) {
                    tag = 'tw';
                } else if (url.indexOf('/de/') !== -1) {
                    tag = 'de';
                } else if (url.indexOf('/es/') !== -1) {
                    tag = 'es';
                } else if (url.indexOf('/fr/') !== -1) {
                    tag = 'fr';
                } else if (url.indexOf('/pt/') !== -1) {
                    tag = 'pt';
                } else if (url.indexOf('/jp/') !== -1) {
                    tag = 'jp';
                } else if (url.indexOf('/it/') !== -1) {
                    tag = 'it';
                } else if (url.indexOf('/id/') !== -1) {
                    tag = 'id';
                } else if (url.indexOf('/ar/') !== -1) {
                    tag = 'ar';
                }
            }

            return tag;
        },

        /**
         * @description 返回指定key的本地化
         * @param key
         * @return {*}
         * @private
         */
        _: function (key) {
            try {
                let val = this.language()[key];
                return lodash.isUndefined(val) ? key : val;
            } catch (e) {
                return key;
            }
        },

        /**
         * @description 切换
         * @param tag
         * @return {Promise<void>}
         */
        switchLanguage: async function (tag) {
            _currentTag = tag;
            _languages[tag] = await FJNetworkStore.fetchLanguage(tag);
            if (!_languages[tag]) {
                throw new Error('No Language');
            } else {
                FJEvent.dispatch(FJEventConstant.LANGUAGE_CHANGED);
            }
        },

        /**
         * @description 返回当前的本地化对象
         * @return {object}
         */
        language: function () {
            return _languages[_currentTag];
        },

        /**
         * @description 当前缓存的tag
         * @return {string}
         */
        currentTag: function () {
            return _currentTag;
        },

        /**
         * @description 当前缓存的tag
         * @return {string}
         */
        getCurrentTag: function () {
            return _currentTag;
        },

        /**
         * @description 当前缓存的tag
         * @return {string}
         */
        setCurrentTag: function (tag) {
            if (tag) {
                _currentTag = tag;
            }
        },

        /**
         * @description 注入本地化
         * @param {string} tag
         * @param {object} data
         */
        injectLanguage: function (tag, data) {
            if (!_languages[tag]) {
                _languages[tag] = data;
                FJEvent.dispatch(FJEventConstant.LANGUAGE_CHANGED);
            }
        },
    };
})();
