import FJConfig from '../config/FJConfig';
import FJLocalStore from '../store/FJLocalStore';
import FJUtil from '../../util/FJUtil';
import localforage from 'localforage';
import { sha256 } from 'js-sha256';

export default {
    getCurrentUrl: function (specialLang = false) {
        const currentLanguage = FJLocalStore.getCurrentTag();
        let url = FJConfig.serverPath;

        if (specialLang && ['it', 'id'].includes(currentLanguage)) {
            url += `/${currentLanguage}`;
        } else if (!['en', 'it', 'id'].includes(currentLanguage)) {
            url += `/${currentLanguage}`;
        }

        return url;
    },
    addEvent: function (element, event, handler, useCapture = false) {
        if (!element) {
            return;
        }
        if (element.addEventListener) {
            element.addEventListener(event, handler, useCapture);
        } else if (element.attachEvent) {
            element.attachEvent('on' + event, handler);
        }
    },
    removeEvent: function (element, event, handler, useCapture = false) {
        if (!element) {
            return;
        }
        if (element.removeEventListener) {
            element.removeEventListener(event, handler, useCapture);
        } else if (element.detachEvent) {
            element.detachEvent('on' + event, handler);
        }
    },
    isLocalhost: function () {
        return Boolean(
            window ||
                window.location.hostname === 'localhost' ||
                // [::1] is the IPv6 localhost address.
                window.location.hostname === '[::1]' ||
                // 127.0.0.1/8 is considered localhost for IPv4.
                window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/) ||
                window.location.hostname.match(/^192.168(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){2}$/),
        );
    },

    /**
     * @description 判断是不是测试环境
     * @returns {boolean}
     */
    isTest: function () {
        return process.env.NEXT_PUBLIC_SERVER_TAG_NODE !== 'release';
    },
    /**
     * @description
     * @param dataurl
     * @returns {Blob}
     */
    dataURLtoBlob: function (dataurl) {
        let arr = dataurl.split(',');
        let mime = arr[0].match(/:(.*?);/)[1];
        let bstr = atob(arr[1]);
        let n = bstr.length;
        let u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
    },
    isMobile: function () {
        const ua = navigator.userAgent.toLowerCase();
        if (ua.match(/mobile/)) {
            return true;
        }
        return false;
    },

    /**
     * @desc 获取当前浏览器
     * @return {string}
     */
    getBrowser: function () {
        const userAgentDataBrowserList = ['Google Chrome', 'Opera', 'Microsoft Edge'];
        if (navigator && navigator.userAgentData) {
            const userAgentData = navigator.userAgentData;
            const isMobile = userAgentData.mobile ? '.Mobile' : '';
            const brands = userAgentData.brands;

            let browser = '';
            for (let i = 0; i < brands.length; i++) {
                const brand = brands[i].brand;
                if (userAgentDataBrowserList.indexOf(brand) !== -1) {
                    switch (brand) {
                        case userAgentDataBrowserList[0]: {
                            browser = 'Chrome' + isMobile;
                            break;
                        }
                        case userAgentDataBrowserList[1]: {
                            browser = 'Opera' + isMobile;
                            break;
                        }
                        case userAgentDataBrowserList[2]: {
                            browser = 'Edge' + isMobile;
                            break;
                        }
                    }
                    break;
                }
            }

            if (browser) {
                return browser;
            }
        }

        let Sys = {};
        let ua = navigator.userAgent.toLowerCase();
        let s;
        let isMobile = '';

        // eslint-disable-next-line no-unused-expressions
        (s = ua.match(/rv:([\d.]+)\) like gecko/))
            ? (Sys.ie = s[1])
            : (s = ua.match(/msie ([\d.]+)/))
            ? (Sys.ie = s[1])
            : (s = ua.match(/edge\/([\d.]+)/))
            ? (Sys.edge = s[1])
            : (s = ua.match(/edg\/([\d.]+)/))
            ? (Sys.edge = s[1])
            : (s = ua.match(/firefox\/([\d.]+)/))
            ? (Sys.firefox = s[1])
            : (s = ua.match(/opera.([\d.]+)/))
            ? (Sys.opera = s[1])
            : (s = ua.match(/opr\/([\d.]+)/))
            ? (Sys.opera = s[1])
            : (s = ua.match(/chrome\/([\d.]+)/))
            ? (Sys.chrome = s[1])
            : (s = ua.match(/version\/([\d.]+).*safari/))
            ? (Sys.safari = s[1])
            : 0;

        if (ua.match(/mobile/)) {
            isMobile = '.Mobile';
        }

        if (Sys.ie) return 'IE' + isMobile;
        if (Sys.edge) return 'Edge' + isMobile;
        if (Sys.firefox) return 'FF' + isMobile;
        if (Sys.opera) return 'Opera' + isMobile;
        if (Sys.safari) return 'Safari' + isMobile;
        if (Sys.chrome) return 'Chrome' + isMobile;
    },
    /**
     * @description 埋点统计
     * @param eventType
     * @param eventData
     */
    ealog: function (eventType, eventData) {
        if (window && window.ealog && window.ealog.addEvent) {
            console.log(eventType, eventData);
            window.ealog.addEvent(eventType, eventData);
        } else {
            console.log(eventType, eventData);
        }
    },

    /**
     * @description 获取本地视频
     * @param {*} name
     * @returns
     */
    getVideoBasePath: function (name) {
        switch (process.env.NEXT_PUBLIC_SERVER_TAG_NODE) {
            case 'local':
                return `/video/${name}.webm`;
            case 'test':
                return `https://resource.flexclip.com/pages/3-steps/${name}.webm`;
            case 'release':
                return `https://resource.flexclip.com/pages/3-steps/${name}.webm`;
            // /images/next_resource/step1out.webm
            default:
                return `/video/${name}.webm`;
        }
    },
    // 将模板搜索数据转换为页面所需数据
    templateDataRequiredForConvertingPages: function (templateData) {
        if (templateData.length <= 0) {
            return [];
        }
        let lists = [];
        templateData.map(item => {
            let name = item.template_name ? item.template_name : 'default';
            name = name.replaceAll(' ', '-');
            lists.push({
                name: FJUtil.convertToNotGonnaCryMeme(name),
                key_word: name,
                imgUrl: `/templates/cover/w400/${name}.webp`,
                templateId: item.template_id,
            });
        });
        return lists;
    },

    // 将驼峰命名转为'-'连接
    changeCamelCaseToLine(str) {
        if (typeof str !== 'string') {
            return str;
        }

        let arr = str.split('');

        // 如果传进来的字符串没有一个大写字母，则直接返回
        if (arr.every(item => item.toUpperCase() !== item)) {
            return str;
        }

        let newArr = arr.map((item, index) => {
            return /^[a-zA-Z]$/.test(item)
                ? index > 0 && item.toUpperCase() === item
                    ? '-' + item.toLowerCase()
                    : item.toLowerCase()
                : item;
        });

        return newArr.join('');
    },

    combineData(data) {
        if (!(typeof data === 'object' && !Array.isArray(data))) {
            return data;
        }
        const result = {};

        let arrLength = 0;

        // 将 key1、key2、key3组合成key:['','','']
        for (let key in data) {
            let sameKey = key.replace(/\d+$/, '');
            let number = key.match(/\d+$/);
            arrLength = number && data[key] ? Math.max(arrLength, number || 0) : arrLength;
            if (!result[sameKey]) {
                result[sameKey] = Array.isArray(data[key])
                    ? [data[key]]
                    : Math.max(number || 0) && data[key]
                    ? [data[key]]
                    : data[key];
            } else {
                if (!Array.isArray(result[sameKey])) {
                    result[sameKey] = [result[sameKey], data[key]];
                } else {
                    result[sameKey].push(data[key]);
                }
            }
        }

        // 将多个key: ['','','']组合成[{key:''},{key:''},{key:''}]
        let combineDataObj = { list: [] };
        for (let key in result) {
            // 已经找到了多个相同key，且已经形成数组的情况
            if (Array.isArray(result[key]) && result[key].length >= 1) {
                for (let index = 0; index < arrLength; index++) {
                    combineDataObj.list[index] = Object.assign({}, combineDataObj.list[index], {
                        [key]: result[key][index],
                    });
                }
            } else {
                combineDataObj[key] = result[key];
            }
        }
        return combineDataObj;
    },

    returnSsrComponentStyle(needContents = {}) {
        const bg_behavior = needContents.bg_behavior;
        let bgStyle = {};
        if (bg_behavior === 0 && needContents.bgColor) {
            bgStyle = {
                backgroundColor: needContents.bgColor,
            };
        } else if (bg_behavior === 1 && Array.isArray(needContents.bgImage)) {
            const bgImage =
                Array.isArray(needContents.bgImage) && needContents.bgImage.length > 0
                    ? this.replaceImgType(needContents.bgImage[0].url || '')
                    : needContents.bgImage || '';
            bgStyle = {
                background: 'url(' + FJConfig.ssRResourceServerAddress + bgImage + ') no-repeat center center',
            };
        }

        return bgStyle;
    },

    /**
     * 小工具选择文件之后处理文件、跳转逻辑
     * @param flies
     * */
    async readFiles(files, params = {}, jumpUrl = key => {}, filesKey = 'fileList') {
        if (files.length < 1) {
            return;
        }

        let input_files = []; // localstorage中存储的文件信息
        let tasks = []; // localforage中存储的文件

        for (let i = 0; i < files.length; i++) {
            let file = files[i];
            // 判断文件大小不能超过3G
            if (file.size > 1073741824 * 3) {
                fjmessage.addMessage(window.tools.messages.too_large, 'warning');
                continue;
            }

            let blob = new Blob([file]);
            const arrayBuffer = await FJUtil.getHashBuffer(blob);
            let buffer = new Uint8Array(arrayBuffer);
            let hash = sha256.create();
            hash.update(buffer);
            let key = hash.hex();

            input_files[i] = {
                key,
                info: {
                    type: file.type,
                    size: file.size,
                    name: file.name,
                },
            };

            let task = [];

            let mainFile = new File([file], file.name, { type: file.type });
            localforage.config({
                name: 'local-storage',
                version: 1.0,
                storeName: 'assets', // Should be alphanumeric, with underscores.
                description: 'Assets store at local storage.',
            });
            task = localforage
                .setItem(key, {
                    data: mainFile,
                    type: file.type,
                    size: file.size,
                    name: file.name,
                })
                .then(function () {
                    // 保存成功
                })
                .then(function (value) {
                    // we got our value
                })
                .catch(function (err) {
                    // we got an error
                });
            tasks.push(task);
        }

        let goToEditor = () => {
            const uploadKey = sha256('chooseFilesInfo');
            // ADD_RECORD_VIDEO replace uploadKey
            localStorage.setItem(uploadKey, JSON.stringify(Object.assign({}, params, { [filesKey]: input_files })));
            location.href = jumpUrl(uploadKey);
        };

        if (tasks.length > 0) {
            Promise.all(tasks).then(() => {
                goToEditor();
            });
        }
    },

    // 将图片后缀为非webp的更改为webp
    replaceImgType(url) {
        const regex = /\.webp$/;
        if (!regex.test(url)) {
            url = url.replace(/\.(?!webp$)[^.]+$/, '.webp');
        }
        return url;
    },

    /**
     *根据需要返回对应的h标签
     * @param  hTag(text): 需要渲染成哪个h标签
     * getContent（text | function）： h标签中显示的内容
     * className: h标签使用的类名
     * style：h标签使用的style*/
    __drawHTag(hTag, getContent = () => {}, className = '', style = {}) {
        const innerText = typeof getContent === 'function' ? getContent() : getContent;
        switch (hTag) {
            case 'h1':
                return (
                    <h1 className={className} style={style}>
                        {innerText}
                    </h1>
                );
            case 'h2':
                return (
                    <h2 className={className} style={style}>
                        {innerText}
                    </h2>
                );
            case 'h3':
                return (
                    <h3 className={className} style={style}>
                        {innerText}
                    </h3>
                );
            case 'h4':
                return (
                    <h4 className={className} style={style}>
                        {innerText}
                    </h4>
                );
            case 'h5':
                return (
                    <h5 className={className} style={style}>
                        {innerText}
                    </h5>
                );
            case 'h6':
                return (
                    <h6 className={className} style={style}>
                        {innerText}
                    </h6>
                );
            default:
                return (
                    <h2 className={className} style={style}>
                        {innerText}
                    </h2>
                );
        }
    },

    /**
     * @desc 数字动画，从0-1，经过多少秒
     * @param duration 过渡时长
     * @param from 起始值
     * @param to 终止值
     * @param onprogress 传递函数，在变化过程中需要做什么事情
     * @param onprogressEnd 传递函数，在变化过程结束后需要做什么*/
    numberAnimation(duration, from, to, onprogress = () => {}, onprogressEnd = () => {}) {
        let value = from;
        let speed = (to - from) / duration;
        const start = Date.now();

        const __run = () => {
            let t = Date.now() - start;
            if (t >= duration) {
                value = to;
                onprogressEnd && onprogressEnd(value);
                return;
            }

            value = Math.ceil(from + t * speed);
            onprogress && onprogress(value);

            return requestAnimationFrame(__run);
        };

        __run();
    },

    /**
     * transformAnimation dom到达视口时进行的动画效果
     * @param dom 需要添加动画的元素
     * @param keyframe 动画关键帧，数据类型为数组，可以添加多个关键帧，一般填写起始、结束两个关键帧
     * @param animationParams 动画配置，如过渡时间、动画快慢、动画状态
     * @param overViewportUseAnimation 已经出现在视口的元素是否也应用动画，默认不应用动画
     */
    AnimationHandler: class AnimationHandler {
        constructor() {
            this.domAnimationMap = new WeakMap();
            this.observer = new IntersectionObserver(entries => {
                for (const entry of entries) {
                    if (entry.isIntersecting) {
                        const animation = this.domAnimationMap.get(entry.target);
                        animation && animation.play();
                        this.observer.unobserve(entry.target);
                    }
                }
            });
        }

        transformAnimation(
            dom,
            keyframe = [
                { transform: 'translateY(50px)', opacity: 0 },
                { transform: 'translateY(0)', opacity: 1 },
            ],
            animationParams = {
                duration: 1000,
                delay: 0,
                easing: 'linear',
                fill: 'forwards',
            },
            overViewportUseAnimation = false,
        ) {
            const dealAnimationFun = () => {
                // 已经出现在视口的元素是否也应用动画，默认不应用动画
                if (!overViewportUseAnimation) {
                    const rect = dom.getBoundingClientRect();
                    const isBelowViewport = rect.top < window.scrollY;
                    if (isBelowViewport) {
                        return;
                    }
                }

                const animation = dom.animate(keyframe, animationParams);
                this.domAnimationMap.set(dom, animation);
                this.observer.observe(dom);
            };

            dealAnimationFun();
        }

        unobserve(dom) {
            this.observer.unobserve(dom);
        }
    },

    packageName: function (userPackage) {
        switch (userPackage) {
            case 'free':
                return FJLocalStore._('FREE_NAME');
            case 'basic':
                return FJLocalStore._('BASIC_NAME');
            case 'plus':
                return FJLocalStore._('PLUS_NAME');
            case 'business':
                return FJLocalStore._('BUSINESS_NAME');
            case 'team':
                return FJLocalStore._('TEAM_NAME');
            default:
        }
    },

    formatNumber: function (value) {
        const currentTag = FJLocalStore.currentTag();
        if (currentTag === 'cn' || currentTag === 'tw' || currentTag === 'jp') {
            if (value < 10000) {
                return value;
            } else {
                let unit = '';
                if (currentTag === 'cn' || currentTag === 'jp') {
                    unit = '万';
                } else if (currentTag === 'tw') {
                    unit = '萬';
                }
                return value / 10000 + unit;
            }
        } else {
            if (value < 10000) {
                return value;
            } else if (value < 1000000) {
                return value / 1000 + 'k';
            } else {
                return value / 1000000 + 'm';
            }
        }
    },

    getRelatedUrl: function () {
        let lange = FJLocalStore.getCurrentTag();

        return lange === 'en' ? FJConfig.serverPath : FJConfig.serverPath + '/' + lange;
    },

    ensureSlashes: function (str) {
        if (!str.startsWith('/')) {
            str = '/' + str;
        }
        if (!str.endsWith('/')) {
            str = str + '/';
        }

        return str;
    },

    removePrefix: (str, prefix) => {
        if (str.startsWith(prefix)) {
            return str.substring(prefix.length);
        }
        return str;
    },
    valueOfInterval(value, min, max) {
        let result = 0;
        result = value < min ? min : value;
        result = result > max ? max : result;
        return result;
    },
    formatTime: function (time, keepMs, mDigit, msDigit, hasAutoHour = false) {
        if (!time && time !== 0) return mDigit === 2 ? '00:00' : '0:00';
        if (typeof time !== 'number') time = Number(time);
        if (isNaN(time)) return mDigit === 2 ? '00:00' : '0:00';

        time = time.toFixed(2);
        let m = Math.floor(time / 60);
        let s = keepMs ? Math.floor(time % 60) : Math.round(time % 60); // 显示毫秒的地方s要用向下取整
        // 小时
        let hour = 0;
        if (s === 60) {
            // 四舍五入可能造成s为60
            m += 1;
            s = 0;
        }
        if (s < 10) s = '0' + s;

        if (hasAutoHour && m >= 60) {
            hour = Math.floor(m / 60);
            m = m % 60;
        }
        if (m < 10 && mDigit === 2) m = '0' + m;

        if (keepMs) {
            time = (Math.round(time * 10) / 10).toFixed(2).toString();
            let ms = time.substr(time.lastIndexOf('.') + 1, msDigit || 2);
            if (hour !== 0) {
                return `${hour}:${m}:${s}.${ms}`;
            }
            return `${m}:${s}.${ms}`;
        } else {
            if (hour !== 0) {
                return `${hour}:${m}:${s}`;
            }
            return `${m}:${s}`;
        }
    },
};
